
<script setup>
import { defineProps } from 'vue'
import { store } from '../store.js'
import CloseIcon from './ui/CloseIcon.vue'
import ShareContainer from './ShareContainer.vue'
import {
    VueSpinner,
} from 'vue3-spinners';
const props = defineProps({
    do_a_new_reading: Function,
    display_full_reading: Function,
    continue_chat: Function
})
</script>

<template>
    <div>
        <transition name="fade">
            <div v-if="store.showResponseContainer" class="response-container">
                <div v-if="store.querent_id" class="x-modal-header" style="margin-top: 16px; margin-right: 16px;">
                    <CloseIcon :on-click="props.do_a_new_reading" />
                </div>
                <h2>{{ store.userMessage }}</h2>

                <div v-if="store.querent_id && store.reading_session?.share_uuid && store.show_full_reading"><ShareContainer /></div>

                <!-- <h3 v-show="!readingCompleted" style="text-align: center;">
            {{ progress_indicator }}
          </h3> -->

                <div class="content-flex-container">
                    <!-- Initial Static Loading -->
                    <div class="response-content">
                        <div class="initial-reading" v-show="!store.show_full_reading">


                            <!--Initial prior to reading: TODO show_full_reading is backwards bool-->
                            <div class="initial-reading" v-show="!store.show_full_reading">

                                <div class="custom-cards-and-descriptions-container">
                                    <!-- Card Descriptions with Images -->
                                    <div v-for="(card, index) in store.selectedCards" :key="card.name"
                                        class="card-and-description">
                                        <div
                                            :style="store.selectedCards[index].reversed ? { transform: 'rotate(180deg)', margin: '16px' } : { margin: '16px' }">
                                            <b>{{ store.selectedCards[index].name }}</b>
                                        </div>
                                        <div style="margin-bottom: 16px">
                                            <span v-if="index === 0"><b>Past</b></span>
                                            <span v-else-if="index === 1"><b>Present</b></span>
                                            <span v-else-if="index === 2"><b>Future</b></span>
                                            <span v-else-if="index === 3"><b>Advice</b></span>
                                        </div>
                                        <!-- Card Image -->
                                        <img :src="card.image_path" :alt="card.name"
                                            :class="['tarot-card', { 'reversed-card': card.reversed }]"
                                            class="card-image-in-description">
                                        <!-- Card Description -->
                                        <!-- <p style="text-align: left">{{ cardDescriptions[index] }}</p> -->
                                        <p style="text-align: left">{{ store.card_readings[`card_${index + 1}`] }}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="!store.show_full_reading" style="width: 100%; text-align: center;">
                            <div v-if="!store.querent_id && !store.allowFullReading">
                                <p>{{ store.loadingMessage }}</p>
                                <p>You can login after your reading is complete.</p>
                            </div>

                            <!-- Login for Full Reading Button -->
                            <button :class="{ 'disabled-button-class': !store.allowFullReading }"
                                :disabled="!store.allowFullReading" v-if="!store.querent_id"
                                @click="store.showLogin = true">
                                <VueSpinner size="30" color="white" /> &nbsp;&nbsp;Login for Full Reading
                            </button>
                        </div>
                        <!-- Full Reading Content -->
                        <div class="full-reading-button-container" v-if="store.querent_id">
                            <button v-if="!store.isLoadingReading && !store.paid_for_reading"
                                @click="props.display_full_reading">
                                {{ !store.paid_for_reading ? '25 ' : '' }}
                                <i v-if="!store.paid_for_reading" class="fas fa-gem" title="25 Emeralds"></i>
                                {{ !store.show_full_reading ? 'Show & Discuss Full Reading' : 'See Cards & Image' }}
                            </button>
                        </div>

                        <!--Actual Full Reading: TODO show_full_reading is backwards bool-->

                        <div class="reading-and-carousel-container" v-show="store.show_full_reading">

                            <div class="custom-cards-and-descriptions-container">


                                <!-- Card Descriptions with Images -->
                                <div v-for="(card, index) in store.selectedCards" :key="card.name"
                                    class="card-and-description">
                                    <div
                                        :style="store.selectedCards[index].reversed ? { transform: 'rotate(180deg)', margin: 16 } : { margin: 16 }">
                                        <b>{{ store.selectedCards[index].name }}</b>
                                    </div>
                                    <div style="margin-bottom: 16px">
                                        <span v-if="index === 0"><b>Past</b></span>
                                        <span v-else-if="index === 1"><b>Present</b></span>
                                        <span v-else-if="index === 2"><b>Future</b></span>
                                        <span v-else-if="index === 3"><b>Advice</b></span>
                                    </div>
                                    <!-- Card Image -->
                                    <img :src="card.image_path" :alt="card.name"
                                        :class="['tarot-card', { 'reversed-card': card.reversed }]"
                                        class="card-image-in-description">
                                    <!-- Card Description -->

                                    <p style="text-align: left">{{ store.card_readings[`card_${index + 1}`] }}</p>
                                </div>
                            </div>
                        </div>




                        <div v-show="store.show_full_reading" style="margin: 16px;">
                            <h2>Your Tarot Reading</h2>

                            <div class="primary-reading-container">
                                <div style="flex: 1">
                                    <div class="primary-reading">
                                        {{ store.primary_reading }}
                                    </div>
                                </div>
                                <div style="flex: 1">
                                    <div class="image-carousel">
                                        <!-- Use v-if on a container element -->
                                        <template v-if="store.imageUrls && store.imageUrls.length > 0">
                                            <!-- Iterate over imageUrls with v-for -->
                                            <img v-for="(url, index) in store.imageUrls" :key="index" :src="url"
                                                alt="Tarot Image" class="carousel-image">
                                        </template>
                                        <!-- Fallback content if imageUrls is empty -->
                                        <span class="primary-reading-image" v-else>
                                            <!-- You can either display a default image -->
                                            <img src="@/assets/crystal-loading.gif" alt="crytal-loading"
                                                class="carousel-image">
                                        </span>
                                    </div>
                                    <span v-if="!store.imageUrls || store.imageUrls.length === 0">
                                        <p>{{ store.loadingMessage }}</p>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div v-if="store.querent_id" class="reading-button-container">
                            <button v-show="store.show_full_reading" @click="do_a_new_reading">
                                <i class="fa-solid fa-plus"></i>New Reading</button>
                        </div>

                        <div class="chat-box-container" v-show="store.querent_id && store.show_full_reading">
                            <!--Ask Follow Up Question -->
                            <div class="chat-container" v-show="store.show_full_reading" ref="chat_container_ref">
                                <div v-for="(message, index) in store.messages" :key="index"
                                    :class="['message-box', message.type === 'user' ? 'user-message' : 'reader-response']">
                                    <p>{{ message.text }}</p>
                                </div>
                            </div>
                            <!-- Follow-up Question Section -->
                            <div :class="{ 'disabled-button-class': !store.allowFullReading }"
                                :disabled="!store.allowFullReading" v-show="store.show_full_reading"
                                class="follow-up-container">
                                <textarea v-model="store.follow_up_user_message" placeholder="Respond"></textarea>
                                <button @click="props.continue_chat"> <i class="fa-solid fa-paper-plane"></i></button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style src="../styles/user-reading-form.css"></style>