import { createApp } from 'vue';
import App from './App.vue';
import Notifications from '@kyvg/vue3-notification'
import { SFacebook } from 'vue-socials';
//import { webSocketService } from './websocketService';

const app = createApp(App);
app.use(Notifications)
app.use(SFacebook)
// Connect to WebSocket
// webSocketService.connect('ws://yourserver/path/to/websocket/');

app.mount('#app');
