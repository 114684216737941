<script setup>
import { onMounted } from 'vue';
import axios from 'axios';
import RouterView from './components/RouterView.vue';
import LoadingOverlay from './components/ui/LoadingOverlay.vue';
import { store, static_store } from './store';
// Configure Axios
axios.defaults.withCredentials = true;

// Methods
async function getCsrfToken() {
  const response = await axios.get(`${static_store.apiUrl}/csrf/`);
  if (response.data.csrfToken) {
    localStorage.setItem('csrfToken', response.data.csrfToken);
    document.cookie = `csrftoken=${response.data.csrfToken};path=/`;
  }
}

// Mounted Lifecycle Hook
onMounted(async () => {
  await getCsrfToken();
});
</script>

<template>
  <div id="app">
    <Notifications position="top center"/>
    <RouterView />
    <loading-overlay v-if="store.is_loading"/>
  </div>
</template>