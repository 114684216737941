// store.js
import { reactive } from 'vue'

export const store = reactive({
  querent_emerald_balance: 0,
  querent_id: '',
  querent_email: '',
  session_id: '',
  reading_session_id: '',
  reading_session: {},
  reading_sessions: [],
  is_loading: false,
  login_current_tab: 'login',


  showResponseContainer: false,
  //do_a_new_reading: Function,
  userMessage: '',
  show_full_reading: false,
  selectedCards: [],
  card_readings: {
    card_1: '',
    card_2: '',
    card_3: '',
    card_4: '',
  },
  allowFullReading: false,
  loadingMessage: '',
  showLogin: false,
  isLoadingReading: false,
  paid_for_reading: false,
  //display_full_reading: Function,
  imageUrls: [],
  messages: [],
  follow_up_user_message: '',
  primary_reading: '',
  follow_up_question: '',
  //continueChat: Function


})

export const static_store = {
  apiUrl: process.env.VUE_APP_DJANGO_API_URL,
  stripe_public_key: process.env.VUE_APP_STRIPE_PUBLIC_KEY
}