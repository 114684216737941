

<template>
  <audio ref="emeraldAudioPlayer" :src="emeraldSoundFile"></audio>
  <audio ref="selectAudioPlayer" :src="selectSoundFile"></audio>
  <audio ref="selectCardAudioPlayer" :src="selectCardFile"></audio>
  <!-- Main Content -->

  <div class="page-container">
    <div class="content-wrap">

      <!-- TOP LEFT EMERALD START -->
      <div>
        <!-- <button @click="test_auth()">Test</button> -->
        <div class="top-left-menu" v-if="store.querent_id && checkingSession == false">
          <ul class="emerald-list">
            <li><a @click.prevent="show_emerald_modal" title="Number of emeralds balance" class="emerald-color">
                <i class="fas fa-gem" title="Emerald Balance"></i> {{ store.querent_emerald_balance }}
              </a>
            </li>
          </ul>
        </div>
        <!-- TOP LEFT EMERALD END -->

        <!-- TOP RIGHT MENU START -->
        <div class="top-right-menu" v-if="store.querent_id"><!-- Hamburger Icon -->
          <button @click="toggleMenu" class="hamburger-icon">
            <i class="fas fa-bars"></i>
          </button>

          <!-- Dropdown Menu -->
          <ul v-if="showMenu" class="nav-dropdown">
            <!-- <li @click="show_account_modal">
              <i class="fa fa-user"></i> Account
            </li> -->
            <li @click="show_reading_history_modal">
              <i class="fa fa-book"></i> Reading History
            </li>
            <!-- <li @click="show_merch_shop_modal">
              <i class="fa fa-shopping-cart"></i> Merch Shop
            </li> -->
            <li @click="sign_out">
              <i class="fa fa-sign-out"></i> Sign Out
            </li>
          </ul>
        </div>
        <!-- TOP RIGHT MENU END -->

        <!-- Card Selection Container -->
        <div class="cards-container" v-show="showCardStack">
          <div class="card" v-for="card in cards" :key="card.name" @click="select_card(card.name)"
            :class="{ 'hidden-card': store.selectedCards.includes(card.name) }">

            <!-- Card Image Container -->
            <div class="card-image">
              <!-- Tarot Card Image (Front) -->
              <img v-if="store.selectedCards.includes(card.name)" :src="card.image_path" :alt="card.name"
                :class="['tarot-card', { 'reversed-card': card.reversed }]">
              <!-- Background Image (Card Back) -->
              <img v-else class="card-back"
                :src="`https://mysticaida.blob.core.windows.net/${azurePublicBucket}/static/card_images/Background/cardback3.jpg`"
                alt="Card Back">
            </div>
          </div>
        </div>

        <!-- Revealed Cards Container -->
        <div class="revealed-cards-container" v-if="store.selectedCards.length">
          <div class="card" v-for="card in filteredCards" :key="card.name">

            <!-- Card Image Container -->
            <div class="card-image">
              <img :src="card.image_path" :alt="card.name" :class="['tarot-card', { 'reversed-card': card.reversed }]">
            </div>
          </div>
        </div>

        <!-- Response Container -->
        <response-container
        :do_a_new_reading="do_a_new_reading"
      :display_full_reading="display_full_reading"
      :continue_chat="continue_chat"
        ></response-container>

        <!-- Container for Revealed Cards START-->
        <div class="revealed-cards-container" v-show="showRevealedCards && !store.show_full_reading">
          <div class="card" v-for="card in revealedCards" :key="card.name">
            <div class="card-image">
              <img :src="card.image_path" :alt="card.name" :class="['tarot-card', { 'reversed-card': card.reversed }]">
            </div>
          </div>
        </div>
        <!-- Container for Revealed Cards END-->


        <!-- Welcome Modal -->
        <transition name="fade">
          <div v-if="showWelcomeModal" class="modal querent-question-modal">
            <div class="modal-content">

              <h1>Welcome to Mystic Aida</h1>

              <h2>Let's get you a tarot reading.</h2>

              <span style="margin: 16px">
                <button class="big-button"
                  @click="() => { store.showLogin = true; showWelcomeModal = false, store.login_current_tab = 'login' }">Login</button>

              </span>
              <span style="margin: 16px">
                <button class="big-button"
                  @click="() => { store.showLogin = true; showWelcomeModal = false, store.login_current_tab = 'register' }">Register</button>

              </span>
            </div>
          </div>
        </transition>


        <!-- Login modal -->
        <transition name="fade">
          <div v-if="store.showLogin" class="login-modal-overlay">
            <div class="login-modal" style="width: 375px">
              <div class="x-modal-header">
                <close-icon :on-click="() => { store.showLogin = false; showWelcomeModal = true }"></close-icon>
              </div>
              <div class="modal-content">
                <login-social @loginSuccess="setThisKeys" :login-methods="['password']"></login-social>
                <!-- <form @submit.prevent="login_submit">
                <input type="email" v-model="userEmail" placeholder="Enter your email" required>
                <button type="submit">Save</button>
              </form> -->
              </div>
            </div>
          </div>
        </transition>

        <!-- <div v-if="!aModalIsOpen() && progress_indicator=='initial'" class="modal">
          <button class="standard-button" @click="showQuerentQuestionModal = true"><i class="fa-solid fa-plus"></i>New Reading</button>
        </div> -->

        <!-- Initial Querent Message START-->
        <transition name="fade">
          <div v-if="showQuerentQuestionModal && !showWelcomeModal && !store.showLogin" class="modal querent-question-modal">
            <div class="modal-content">
              <form @submit.prevent="handleModalInitialMessage">
                <div style="padding-top: 8px;">
                  <label style="font-size: 18px; font-weight: bold;" for="message">What can I help you see?</label>
                  <textarea id="message" v-model="store.userMessage" rows="5" cols="33"></textarea>
                </div>
                <button class="standard-button" type="submit">Select Cards</button>
              </form>
            </div>
          </div>
        </transition>
        <!-- Initial Querent Message END-->


        <!-- Get Emeralds Modal -->
        <transition name="fade">
          <div v-if="showGetEmeraldsModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <div v-if="checkoutStatus === 'checkout'">
                <div v-if="showStripeCardElement">
                  <div id="payment-element"><!-- Mount the Payment Element here --></div>
                  <div class="payment-button-container">
                    <button class="standard-button" @click="submitPayment" :disabled="store.is_loading">Submit
                      Payment</button>
                  </div>
                </div>
              </div>

              <div v-if="checkoutStatus === ''">
                <h3>Buy Emeralds</h3>
                <div class="products-container">
                  <div v-for="product in readerProducts" :key="product.stripe_product_id" class="product-card">
                    <div class="product-image-container">
                      <img :src="get_full_image_url(product.image)" alt="Product Image" class="product-image">
                    </div>
                    <div class="product-info">
                      <h3>{{ product.name }}</h3>
                      <div v-for="price in product.default_prices" :key="price.stripe_price_id" class="price-info">
                        <p class="price">Price: ${{ price.price }}</p>
                        <button @click="handleBuyButtonClick(price.stripe_price_id)"
                          class="standard-button buy-button">Buy</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="checkoutStatus === 'success'">
                <p>We appreciate your business! If you have any questions, please email <a
                    href="mailto:support@mysticAIda.ai">support@mysticAIda.ai</a>.</p>
              </div>
              <div v-else-if="checkoutStatus === 'cancel'">
                <p>Forgot to add something to your cart? Shop around then come back to pay!</p>
              </div>
            </div>
          </div>
        </transition>

        <!-- Account Modal -->
        <transition name="fade">
          <div v-if="showAccountModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <h3>Account</h3>
              <div style="color: black">
                {{ store.querent_email }}
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <!-- Reading History Modal -->
          <div v-if="showReadingHistoryModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <reading-history></reading-history>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <!-- Merch Shop -->
          <div v-if="showMerchShopModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <h3>Merch Shop</h3>
              <!-- Modal content for Merch Shop -->
            </div>
          </div>
        </transition>

        <!-- Main Container END -->
      </div>

    </div>
    <footer class="sticky-footer">
      © {{ currentYear }} TechStart Innovators | Please contact <a
        href="mailto:support@mysticAIda.ai">support@mysticAIda.ai</a> for any questions or concerns.
    </footer>
  </div>
</template>


<script>
import emeraldSoundFile from '@/assets/emerald_sound.mp3';
import selectSoundFile from '@/assets/select_sound.mp3';
import selectCardFile from '@/assets/select_card.mp3';
import { store, static_store } from '../store.js'
import axios from 'axios';
axios.defaults.withCredentials = true;
import { mockReading } from '../mocks.js';
import LoginSocial from './LoginSocial.vue'
import ReadingHistory from './ReadingHistory.vue'
import ResponseContainer from './ResponseContainer.vue';
import CloseIcon from './ui/CloseIcon.vue'
import StorageManager from '../utils/general-utils';
import { safeBool } from '../utils/general-utils';
import { getQuerent, loadReadingSession, getFullReading } from '../api-service.js'

if (!mockReading) {
  console.log("we need a reference to mockReading to avoid lint errors. This is for debugging.")
}

export default {
  components: {
    // LoginPage,
    LoginSocial,
    ReadingHistory,
    ResponseContainer,
    CloseIcon,
  },
  data() {
    return {
      emeraldAudioPlayer: null,
      emeraldSoundFile: emeraldSoundFile,
      selectSoundFile: selectSoundFile,
      selectCardFile: selectCardFile,
      webSocketURL: process.env.VUE_APP_WEB_SOCKET_URL,
      azurePublicBucket: process.env.VUE_APP_AZURE_PUBLIC_BUCKET,
      store: store,
      static_store: static_store,
      currentYear: new Date().getFullYear(),
      showCardStack: false,
      showMenu: false,
      cards: [],
      showQuerentQuestionModal: false,
      revealedCards: [], // Stores the carAudioPlayer
      imageUrl: '',
      progress_indicator: 'welcome-modal',
      card_1: '',
      card_2: '',
      card_3: '',
      card_4: '',
      userEmail: '',
      initial_reading_package: '',
      querent_created: false,
      follow_up_response: '',
      showRevealedCards: true,
      showGetEmeraldsModal: false,
      showAccountModal: false,
      showReadingHistoryModal: false,
      showMerchShopModal: false,
      readerProducts: [],
      checkoutStatus: '',
      ws: null, // WebSocket instance
      stripe: null,
      cardElement: null,
      showStripeCardElement: false,
      purchase_key: null,
      client_secret: null,
      payment_element: null,
      purchase_intent_id: null,
      cardDescriptions: [],
      delayedReadingTimout: null,
      streamingChat: false,
      streamChatToMessageId: -1,
      checkingSession: true,
      showWelcomeModal: false
    };
  },

  async mounted() {

    this.emeraldAudioPlayer = this.$refs.emeraldAudioPlayer;

    const loadingMessages = [
      "Gazing into the orb... Your vision is crystallizing. Patience, the magic unfolds!",
      "Divining insights... Hold tight as the crystal reveals its secrets.",
      "Unraveling mysteries... Await a moment as the sphere enlightens us.",
      "The future is becoming clear... Stay with me as I focus the crystal's gaze.",
      "Seeking foresight... Let the crystal ball illuminate your path. Almost there!",
      "Channeling visions... The crystal is aligning with your destiny. Hold on!",
      "Scrying the unseen... The orb's light is weaving your tale. Stay tuned!",
      "Crystal clarity ahead... Anticipate a moment as the orb's magic awakens.",
      "Oracular insights in progress... The crystal is shaping your narrative. Patience!",
      "Eclipsing the unknown... As the orb glows, so does your revelation. Momentarily!"
    ]

    this.store.loadingMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];

    await this.checkForSession();
    await this.handleOAuthCallback();

    let url = this.static_store.apiUrl + '/reading/get-four-card-spread/?';

    if (this.store.reading_session_id) {
      url += `reading_session_id=${encodeURIComponent(this.store.reading_session_id)}`;
      url += `&querent_id=${encodeURIComponent(this.store.querent_id)}`;
    }
    else {
      if (this.store.querent_id) {
        url += `querent_id=${encodeURIComponent(this.store.querent_id)}`;
        this.showQuerentQuestionModal = true;
      }
    }
    console.log("get-four-card-spread-url -> ", url)
    axios.get(url)  // Load all cards for the select options
      .then(response => {
        this.cards = response.data.cards;

        // this code works, there is just 130MB of images
        //this.preloadImages(this.cards.map((x) => x.image_path));
        const server_session_id = response.data.reading_session_id

        if (this.store.reading_session_id !== server_session_id) {
          console.log("Set reading_session_id from server -> " + server_session_id)
          localStorage.setItem('reading_session_id', server_session_id, { expires: 1 });
          this.store.reading_session_id = response.data.reading_session_id
        }

        this.connectWebSocket();
      })
      .catch(error => console.error(error));
  },
  beforeUnmount() {
    if (this.ws) {
      this.ws.close(); // Ensure WebSocket is closed when component is destroyed
    }
  },
  watch: {
    showGetEmeraldsModal(newValue) {
      if (newValue === true) {
        this.showStripeCardElement = true;
        this.initializeStripe();
      }
      this.checkoutStatus = ''

    }
  },
  computed: {
    filteredCards() {
      return this.cards.filter(card => this.store.selectedCards.includes(card.name));
    },
    isPlayingEmeraldSound() {
      return this.emeraldAudioPlayer && !this.emeraldAudioPlayer.paused && !this.emeraldAudioPlayer.ended && this.emeraldAudioPlayer.currentTime > 0;
    }
  },
  methods: {
    preloadImages(imagesToPreload) {
      console.log("prelading these images", imagesToPreload)
      imagesToPreload.forEach(url => {
        const img = new Image();
        img.src = url;
      });
    },
    aModalIsOpen() {
      if (this.showGetEmeraldsModal || this.showAccountModal || this.showReadingHistoryModal || this.showMerchShopModal) {
        return true
      }
      return false
    },
    playEmeraldSound() {
      if (!this.isPlayingEmeraldSound) {
        this.$refs.emeraldAudioPlayer.play();
      }
    },
    playSelectSound() {
      this.$refs.selectAudioPlayer.play();
    },
    playSelectCardSound() {
      this.$refs.selectCardAudioPlayer.play();
    },
    scrollChatBox() {
      setTimeout(() => {
        const textarea = this.$refs.chat_container_ref;
        if (textarea) {
          textarea.scrollTop = textarea.scrollHeight;
        }
      }, 100); // Timeout with 0ms delay, just to defer until after the DOM updates
    },
    connectWebSocket() {
      this.ws = new WebSocket(this.webSocketURL + '/ws/emeralds/?socket_session_id=' + this.store.reading_session_id)
      this.ws.onopen = () => {
        console.log('WebSocket connected');
        // Additional logic for when the connection opens
      };
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        //console.log('Received message:', data);
        if (data.context) {
          switch (data.context) {
            case 'card_reading':
              this.store.card_readings['card_' + data.reference] += data.message
              break;
            case 'primary_reading':
              this.store.primary_reading += data.message
              break;
            case 'follow_up_question':
              if (this.store.messages.length === 0) {
                this.store.messages.push({ text: data.message, type: 'reader' });
                this.scrollChatBox()
              }
              else {
                this.store.messages[0].text += data.message
                this.scrollChatBox()
              }
              break;
            case 'progress':
              console.log("set progress indicator", data)
              this.progress_indicator = data.message
              if (data.message === 'Summarize reading') {
                this.store.isLoadingReading = false;
              }
              if (data.message === 'Get Illustrative Text') {
                this.store.allowFullReading = true
                if (!this.store.querent_id) {
                  this.store.showLogin = true;
                }
              }
              break;
            case 'chat-box':
              if (this.store.messages[this.streamChatToMessageId]) {
                this.store.messages[this.streamChatToMessageId].text += data.message
                this.scrollChatBox()
              }
              else {
                this.store.messages.push({ text: data.message, type: 'reader' });
                this.scrollChatBox()
              }
              break;
            case 'other':
              console.log("set other", data)
              break;
            default:
              console.log('Received socket chat message:', data);
          }
        }
        // Handle incoming WebSocket messages
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        // Handle errors
      };

      this.ws.onclose = () => {
        console.log('WebSocket disconnected');
        // Handle WebSocket closing
      };
    },
    async checkForSession() {
      this.checkingSession = true;
      console.log("checkForSesssion ->")
      this.store.reading_session_id = localStorage.getItem('reading_session_id') || '';
      this.store.querent_id = localStorage.getItem('querent_id')
      // override cookie / etc. with URL param, TODO this should probably require a security check on server that reading session matches querent id
      const url_param_reading_session_id = this.getUrlParam('reading_session_id');
      if (url_param_reading_session_id) {
        this.store.reading_session_id = url_param_reading_session_id
        localStorage.setItem('loadSession', true);
      }
      console.log("checkForSesssion -> this.store.reading_session_id / reading_session_id", this.store.reading_session_id)

      if (this.store.reading_session_id) {
        // check if this is a callback from social media login and we need to load existing reading
        let loadSession = safeBool(localStorage.getItem('loadSession'), false)
        loadSession = true;
        if (loadSession == true) {
          const response = await loadReadingSession();
          console.log("loadSession response ->", response)
          if (response.data?.selected_cards?.length >= 4) { // go to reading
            //this.store.selectedCards = response.data.selected_cards
            this.handleReadingReponse(response)
            this.showQuerentQuestionModal = false;  // Hide the modal
            //this.store.allowFullReading = true
          }
          //if (response.data?.is_paid) {
           //console.log("paid for session, showing ->")
            //this.store.paid_for_reading = true
            //this.store.show_full_reading = true;
            //this.store.allowFullReading = true
          //}
        }

      }
      if (this.store.querent_id) {
        await getQuerent(this.store.querent_id)
        this.progress_indicator = 'initial'
        this.showQuerentQuestionModal = true;
      }
      else {
        this.showWelcomeModal = true
      }
      this.checkingSession = false;
    },
    async handleBuyButtonClick(stripe_price_id) {
      try {
        const data = { stripe_price_id: stripe_price_id, querent_id: this.store.querent_id };
        const response = await axios.post(this.static_store.apiUrl + '/stripe-create-payment-intent/', data);
        const client_secret = response.data.clientSecret;
        this.showStripeCardElement = true;  // Show the card element for user input
        this.checkoutStatus = 'checkout';
        this.client_secret = client_secret;
        this.purchase_intent_id = response.data.purchase_intent_id;

        const appearance = { theme: 'stripe', };
        const Stripe = await this.loadStripe();

        this.stripe = Stripe(this.static_store.stripe_public_key);

        const elements = this.stripe.elements({ clientSecret: client_secret, appearance: appearance });
        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");

        this.elements = elements
        this.payment_element = paymentElement

      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    },
    async submitPayment() {
      this.store.is_loading = true;
      this.checkoutStatus = 'checkout';
      const client_secret = this.client_secret;
      const elements = this.elements;

      try {
        // First, submit the payment details
        const submissionResult = await elements.submit();

        // Check if the submission was successful
        if (submissionResult.error) {
          alert(submissionResult.error.message);
          return;
        }

        // Then, confirm the payment with the submitted details
        const { error } = await this.stripe.confirmPayment({
          elements: elements,
          clientSecret: client_secret,
          confirmParams: {
            return_url: this.static_store.apiUrl + '/stripe-payment-success/', // TODO: Need to build VUE routers for redirects
          },
          redirect: "if_required",
        });

        // Handle the outcome of the confirmation process
        if (error) {
          this.checkoutStatus = 'cancel';
        } else {
          this.checkoutStatus = 'success';
          let url_address = this.static_store.apiUrl + `/stripe-payment-success/?`;
          url_address += `querent_purchase_intent_id=${encodeURIComponent(this.purchase_intent_id)}`;
          url_address += `&client_secret=${encodeURIComponent(client_secret)}`;
          const response = await axios.get(url_address);
          this.animateEmeraldsCount(this.store.querent_emerald_balance, response.data.emeralds_balance);
        }
      } catch (error) {
        // Handle any unexpected errors
        console.error('Error during the payment process:', error);
        alert('An error occurred during the payment process.');
      } finally {
        this.store.is_loading = false;
      }
    },
    animateEmeraldsCount(oldBalance, newBalance) {
      let startValue = oldBalance;
      let endValue = newBalance;
      let incrementAmount = endValue - startValue; // Calculate the increment amount
      let baseDuration = 1000; // Base duration in milliseconds
      let maxDuration = 15000; // Maximum duration
      let minDuration = 5000; // Minimum duration

      // Determine the duration based on the increment amount
      // Larger increments will have shorter duration and vice versa
      let duration = Math.max(minDuration, Math.min(maxDuration, baseDuration - incrementAmount * 10));

      let stepTime = 50; // Update every 50ms
      let steps = duration / stepTime;
      let increment = incrementAmount / steps;
      let interval = setInterval(() => {
        this.playEmeraldSound()
        this.store.querent_emerald_balance = Math.round(startValue)
        startValue += increment;
        oldBalance = Math.round(startValue);
        if (startValue >= endValue) {
          clearInterval(interval);
          this.store.querent_emerald_balance = endValue; // Ensure it ends on the exact value
          //this.querent_emerald_balance = endValue; // Ensure it ends on the exact value
        }
      }, stepTime);
    },
    async loadStripe() {
      if (window.Stripe) {
        return window.Stripe;
      }
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.onload = () => resolve(window.Stripe);
        document.head.appendChild(script);
      });
    },
    async initializeStripe() {
      if (this.stripe) {
        // Stripe is already initialized
        return;
      }
      try {
        const Stripe = await this.loadStripe();
        this.stripe = Stripe(this.static_store.stripe_public_key);

      } catch (error) {
        console.error("Error loading Stripe:", error);
      }
    },
    get_full_image_url(imagePath) {
      return `https://mysticaida.blob.core.windows.net/${this.azurePublicBucket}/` + `static${imagePath}`
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    do_a_new_reading() {
      localStorage.removeItem('reading_session_id')
      window.location.href = '/';
    },
    hideRevealedCards() {
      this.showRevealedCards = false;
    },
    async fetchReaderProducts() {
      try {
        const response = await axios.get(this.static_store.apiUrl + '/querent/get-emeralds/'); // Adjust URL as needed
        this.readerProducts = response.data.products;
      } catch (error) {
        console.error("Error fetching reader products:", error);
      }
    },
    hide_all_modals(checkForInitial = false) {
      this.showQuerentQuestionModal = false;
      this.showGetEmeraldsModal = false;
      this.showMenu = false;
      this.showAccountModal = false;
      this.showReadingHistoryModal = false;
      this.showMerchShopModal = false;
      if (checkForInitial) {
        if (this.progress_indicator == 'initial') {
          this.showQuerentQuestionModal = true;
        }
      }
    },
    async show_emerald_modal() {
      await this.fetchReaderProducts();
      this.hide_all_modals();
      this.showGetEmeraldsModal = true;
    },
    show_account_modal() {
      this.hide_all_modals();
      this.showAccountModal = true;
    },
    async show_reading_history_modal() {
      try {
        this.store.is_loading = true;
        await getQuerent(this.store.querent_id)
        this.hide_all_modals();
        this.showReadingHistoryModal = true;
      }
      catch (error) {
        console.error("Error fetching reader history:", error);
      }
      finally {
        this.store.is_loading = false;
      }
    },
    show_merch_shop_modal() {
      this.hide_all_modals();
      this.showMerchShopModal = true;
    },
    sign_out() {
      StorageManager.clearAllStorage();
      window.location.href = '/';
    },
    async display_full_reading() {
      if (!this.store.querent_id) {
        this.store.showLogin = true;
      } else {
        if (!this.store.paid_for_reading && this.store.querent_emerald_balance >= 25) {
          try {
            await this.deductEmeralds();
            this.store.paid_for_reading = true;
            this.store.show_full_reading = true;
            this.playSelectSound()
          } catch (error) {
            console.error("Error deducting emeralds:", error);
            // Handle errors (e.g., show an error message)
            return;
          }
        }
        else {
          await this.show_emerald_modal()
        }
      }
    },
    async deductEmeralds() {
      try {
        const response = await axios.get(this.static_store.apiUrl + '/querent/charge-for-reading/', {
          params: {
            querent_id: this.store.querent_id,
            reading_session_id: this.store.reading_session_id
          }
        });

        if (response.data.status === 'success') {
          this.store.querent_emerald_balance = response.data.emeralds_balance;
        } else {
          // Handle any non-success response here
          console.error('Error charging for reading:', response.data.error);
        }
      } catch (error) {
        console.error('Network or other error:', error);
        // Handle network or other errors here
      }
    },
    handleModalInitialMessage() {
      // Check if the userMessage is empty
      if (this.store.userMessage === '') {
        this.$notify({
          text: 'What do you want to say?',
          type: 'info',

          // duration: 3000,
          // speed: 1000
        })
      } else {
        this.showQuerentQuestionModal = false;  // Hide the modal
        this.showCardStack = true; // Show the card stack
        this.progress_indicator = 'Selecting cards...'
      }
    },
    select_card(cardName) {
      if (this.store.selectedCards.length >= 4) {
        return;
      }
      this.playSelectCardSound()
      const index = this.cards.findIndex(card => card.name === cardName);
      if (index !== -1) {
        // Construct the card object with name and reversed status
        const selectedCard = {
          name: this.cards[index].name,
          reversed: this.cards[index].reversed,
          description: this.cards[index].description,
          reverse_description: this.cards[index].reverse_description,
          image_path: this.cards[index].image_path
        };
        // Add to revealed cards and selectedCards
        this.revealedCards.push(this.cards[index]);
        this.store.selectedCards.push(selectedCard);

        // Determine and add the appropriate description
        const description = selectedCard.reversed ? selectedCard.reverse_description : selectedCard.description;
        this.cardDescriptions.push(description);

        // Remove from the main deck
        this.cards.splice(index, 1);

        // Check if four cards have been selected
        if (this.store.selectedCards.length >= 4) {

          this.getCardReading();
          this.delayedReadingTimout = setTimeout(() => {
            this.store.showResponseContainer = true
            this.readingCompleted = false;
            this.hideRevealedCards();
          }, 2000);  // Delay the reading by 1 second
          // Hide the card stack

        }
      }
    },
    setThisKeys(keys) {
      console.log("set this keys ->", keys)
      for (const [key, value] of Object.entries(keys)) {
        this[key] = value
      }
    },
    getUrlParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },
    async handleOAuthCallback() {
      const authToken = localStorage.getItem('authToken');
      const querentId = localStorage.getItem('querent_id');
      if (!authToken || !querentId) {
        console.log("no quth token or querent id attempt to get google code from URL")
        const code = this.getUrlParam('code');
        if (code) {
          try {
            const response = await axios.post(this.static_store.apiUrl + '/dj-rest-auth/google/', {
              code: code,
              redirect_uri: window.location.origin,
              reading_session_id: this.store.reading_session_id,
            }, {
              headers: {
                'X-CSRFToken': localStorage.getItem('csrfToken')
              }
            });
            if (response.data.querent_id) {
              localStorage.setItem('authToken', response.data.key); // Store the token
              localStorage.setItem('querent_id', response.data.querent_id); // Store the token
              this.store.querent_id = response.data.querent_id
              this.$notify({
                text: 'Login Successful',
                type: 'success',

                // duration: 3000,
                // speed: 1000
              })
            }
          } catch (error) {
            console.error('Error exchanging code for token:', error);
          }
        }
        else {
          console.log("no google code from url, verify token here?")
        }
      }
      else {
        if (querentId) {
          this.store.querent_id = querentId
        }
      }

    },
    handleReadingReponse(response) {
      clearTimeout(this.delayedReadingTimout);
      //this.store.showResponseContainer = true
      this.hideRevealedCards();
      this.initial_reading_package = response;
      //this.store.imageUrls.push(response.data.image_url); // Append new image URL
      this.card_1 = response.data.card_1
      this.card_2 = response.data.card_2
      this.card_3 = response.data.card_3
      this.card_4 = response.data.card_4
      console.log("handleReadingReponse ->", response.data.reading)
      // if (response.data.reading) {
      //   this.store.primary_reading = response.data.reading
      // }
      // Update selectedCards with descriptions
      if (this.store.selectedCards.length >= 4) {
        // this.store.selectedCards[0].description = response.data.card_1;
        // this.store.selectedCards[1].description = response.data.card_2;
        // this.store.selectedCards[2].description = response.data.card_3;
        // this.store.selectedCards[3].description = response.data.card_4;
        // override any socket messages in case of broken sockets
        this.progress_indicator = 'Reading complete.'
        // this.store.card_readings.card_1 = response.data.card_1;
        // this.store.card_readings.card_2 = response.data.card_2;
        // this.store.card_readings.card_3 = response.data.card_3;
        // this.store.card_readings.card_4 = response.data.card_4;
      }
      //this.follow_up_question = response.data.follow_up_question;
      this.scrollChatBox()
      // if (this.store.messages.length === 0) {
      //   //this.store.messages.push({ text: this.follow_up_question, type: 'reader' });
      //   this.scrollChatBox()
      // }
      // else {
      //   //this.store.messages[0].text = this.follow_up_question
      //   this.scrollChatBox()
      // }
      // if (response?.data?.reading_session_conversations?.length > 0) {
      //   response.data.reading_session_conversations.map((message) => {
      //     if (message.type === 'ConversationType.CHAT') {
      //       let message_type = 'user'
      //       if (message.from_reader) {
      //         message_type = 'reader'
      //       }
      //       //this.store.messages.push({ text: message.message, type: message_type });
      //     }
      //   });
      //   this.scrollChatBox()
      // }
      // if (response?.data?.user_message) {
      //   this.store.userMessage = response.data.user_message
      // }
      // this.store.isLoadingReading = false;
      this.readingCompleted = true;
    },
    test_auth() {
      const token = localStorage.getItem('authToken')
      axios.post(this.static_store.apiUrl + '/reading/test/', {}, { // Data object is empty here
        headers: {
          Authorization: `Token ${token}`
        }
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.error('Error:', error);
      });
    },
    async getCardReading() {
      if (this.store.isLoadingReading) {
        return;
      }
      this.store.isLoadingReading = true;
      this.progress_indicator = 'Reading your cards..'
      this.showCardStack = false;

      const postData = {
        message: this.store.userMessage,
        selected_cards: this.store.selectedCards,
        reading_session_id: this.store.reading_session_id,
        querent_id: this.store.querent_id,
      };

      postData.socket_session_id = this.store.reading_session_id

      try {
        //const response = await Promise.resolve(mockReading)
        const response = await getFullReading(postData);
        this.handleReadingReponse(response)
      }
      catch (error) {
        console.error(error);
        this.readingCompleted = true;
      }
      finally {
        this.store.isLoadingReading = false;
      }
    },
    continue_chat() {
      if (!this.store.follow_up_user_message || this.streamingChat) {
        // don't allow empty or submitting another message before completing call
        return;
      }
      this.streamingChat = true;
      this.store.messages.push({ text: this.store.follow_up_user_message, type: 'user' });
      this.scrollChatBox()
      this.streamChatToMessageId = this.store.messages.length; // will stream to a new id at index of length

      const postData = {
        user_message: this.store.follow_up_user_message,
        reading_session_id: this.store.reading_session_id,
        socket_session_id: this.store.reading_session_id,
      };
      this.store.follow_up_user_message = '';
      axios.post(this.static_store.apiUrl + '/reading/querent-follow-up/', postData)
        .then(response => {
          if (response.data?.message) {
            // in case of streaming issue set to the return value of http request, should be redundant
            if (this.store.messages[this.streamChatToMessageId]) {
              this.store.messages[this.streamChatToMessageId].text = response.data.message
              this.scrollChatBox()
            }
            else {
              this.store.messages.push({ text: response.data.message, type: 'reader' });
              this.scrollChatBox()
            }
          }
          if (response.data?.querent_emerald_balance) {
            this.store.querent_emerald_balance = response.data.querent_emerald_balance;
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {

          this.streamingChat = false
        });
    }
  }
};
</script>

<style src="../styles/user-reading-form.css"></style>


