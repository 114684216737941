



<template>
  <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; margin-bottom: 32px;">
    
    
    <div style="cursor: pointer;" @click="() => onSetShared()" v-if="!store?.reading_session?.is_shared">
      <h3>Share your Reading? <i style="font-size: 32px" class="fa-regular fa-square"></i></h3>
    </div>
    <div style="cursor: pointer;" @click="() => onSetShared()" v-if="store?.reading_session?.is_shared">
      <h3>Share your Reading? <i style="font-size: 32px" class="fa-regular fa-square-check"></i></h3>
    </div>

    <div v-if="store?.reading_session?.is_shared">
      <s-facebook :window-features="windowFeatures" :share-options="shareOptionsFacebook" :use-native-behavior="useNativeBehavior"
      @popup-close="onClose" @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
      <i style="font-size: 48px;" class="fa-brands fa-facebook"></i>
    </s-facebook>
  </div>



  </div>
</template>
  
<script>
import { SFacebook } from 'vue-socials'
import { store } from '../store.js'
import { toggleShare } from '../api-service'

export default {
  name: 'SFacebookSharing',

  components: { SFacebook },

  data() {
    return {
      windowFeatures: {},
      shareOptionsFacebook: {
        url: process.env.VUE_APP_FRONTEND_URL + '/share?share_uuid=' + store?.reading_session?.share_uuid,
        quote: 'Check out my Mystic Aida Reading!',
        hashtag: '#Tarot Reading',
      },
      useNativeBehavior: false,
      store: store,
    }
  },
  mounted(){
    console.log("SHARE ID", process.env.VUE_APP_FRONTEND_URL + '/share?share_uuid=' + this.store?.reading_session?.share_uuid)
  },  
  methods: {
    
    async onSetShared() {
      await toggleShare(!store?.reading_session?.is_shared)
    },
    onClose() { },
    onOpen() { },
    onBlock() { },
    onFocus() { },
  }
};
</script>

